import * as React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { ArrowRightIcon, RssIcon } from '@heroicons/react/24/outline'
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"

const BlogLandingPage = props => {

  const socialImage = {
    src: "/social.png",
    height: 2304,
    width: 4096
  }

  const { edges: posts } = props.data.allMarkdownRemark

  return (
    <Layout>
      <Seo
        title="Blog"
        description="Announcements, reflections and news from our engineers and designers"
        image={socialImage}
      />
      <aside className="flex flex-row items-center justify-between max-w-screen-md mx-4 mb-10 lg:mx-auto">
        <h2 className="text-4xl font-light text-anomaly-red">
          <Link to="/blog">
            Blog
          </Link>
        </h2>
        <Link to="/rss.xml" className="flex flex-row items-center justify-between text-twitter-blue">
          RSS feed
          <RssIcon className="h-6 ml-2" />
        </Link>
      </aside>
      <ol className="lg:mx-auto lg:max-w-screen-md">
        {posts
          .filter(post => post.node.frontmatter.title.length > 0)
          .map(({ node: post }) => {
            return (
              <li className="mb-10 bg-white border border-gray-200 shadow-md" key={post.id}>
                <GatsbyImage
                  height={992}
                  image={getImage(post.frontmatter.hero_image)}
                  alt={post.frontmatter.hero_image_alt}
                />
                <div className="p-6">
                  <Link to={post.frontmatter.path}>
                    <h1 className="text-xl font-medium text-anomaly-red">{post.frontmatter.title}</h1>
                  </Link>
                  <h2 className="my-2 text-gray-500">{post.frontmatter.author} on {post.frontmatter.date}</h2>
                  <p className="my-2 text-sm text-gray-500">{post.frontmatter.description}</p>
                  <Link className="flex items-center text-anomaly-red" to={post.frontmatter.path}>
                    Read the post
                    <ArrowRightIcon className="h-4 ml-2" />
                  </Link>
                </div>
              </li>
            )
          })}
      </ol>
    </Layout>
  )
}

export const pageQuery = graphql`
query BlogPostIndexQuery {
  allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
    edges {
      node {
        excerpt(pruneLength: 250)
        id
        frontmatter {
          title
          date(formatString: "MMMM DD, YYYY")
          path
          description
          author
          category
          hero_image_alt
          hero_image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
}
`;

export default BlogLandingPage

